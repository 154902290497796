import ZbResetPasswordMixin from './ZbResetPasswordMixin'
import { mapActions } from 'vuex'

export default {
  data: () => ({
    loading: false
  }),
  name: 'ZbResetPasswordComponent',
  mixins: [ ZbResetPasswordMixin ],
  methods: {
    ...mapActions('authModule', ['sendPasswordEmail']),
    async resetPassword () {
      this.loading = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        await this.sendPasswordEmail({ url: 'auth/reset-password', email: this.resetPasswordData.email })
        this.loading = false
        this.resetPasswordData.email = ''
      }
    },
    backLogin () {
      this.$router.push('login')
    }
  }
}
